export const footerItems = {
    "introduction": {
        logoSrc: "./images/logo.png",
        logoAlt: "Footer Logo",
        intro: ""
    },
    "Discreet Services": [
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },{
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        },
        {
            route: "/services/bug-sweeps",
            label: "GPS Tracking"
        }
    ],
    "offices": [
        {
            name: "Chennai Office",
            tel: "1234567890",
            address: "chennai"
        },
        {
            name: "Chennai Office",
            tel: "1234567890",
            address: "chennai"
        },
        {
            name: "Chennai Office",
            tel: "1234567890",
            address: "chennai"
        }
    ]
}