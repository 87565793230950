import React, { useState } from 'react';
import { footerItems } from '../../constants/footerItems';

const AddressBlock = () => {

    const [offices] = useState(footerItems["offices"])
  return (
    <>
        <h3>Contact Us</h3>
            <ul className="services services--office">
                {offices.map(({name, tel, address}, index) => {
                    return (
                        <React.Fragment key={index}>
                        <li className={index === 0 ? "officeName" : "office officeName"}>{name}</li>
                        <li className="tel">{tel}</li>
                        <li>{address}</li>
                        </React.Fragment>
                    )
                })}
            </ul>	
    </>
  )
}

export default AddressBlock