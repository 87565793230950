import React from 'react'

const HomeIntro = ({src, alt, title, children}) => {
  return (
    <div id="about" className="section section--white">
	    <div className="container">
		
                <div className="block40">
                    <img src={src} alt={alt} className="fullWidth borderImage"/>
                </div>

                <div className="block60 pdLeft5 secondBlock">
                    <h2>{title}</h2>
                    {children}

                </div>
                
                <div className="clear"></div>

            </div>
        </div>
  )
}

export default HomeIntro