const pageDetails = {
    "bug-sweeps": [
        {
            src: "./images/bug-sweeps/bug-sweeps-inner",
            alt: "GPS Tracking",
            desc: ["We live in a time where there have been some massive technological innovations and this has helped the development of smaller bugs that can listen in as well as watch you in your home, your business and create corporate espionage opportunities for your competitors."]
        },
        {
            title: "Do I really need counter surveillance GPS Tracking?",
            desc: [
                "You would be surprised by the number of clients we have worked with that have been bugged in different ways over the years. Some of these bugs are so small and planted in places that you would not expect. So running a bug sweep every so often is always advised if you are in a situation where information leaking could cost you dearly.",
                "Bugs are so small and cheap these days that anyone can get access to them and plant them around your home, your office or even your car.&nbsp;&nbsp;",
                "A quick eBay search will show you how cheap bugs can be these days and these are the non-professional ones. Even the non-professional ones can be fitted into places where you would not expect and these cheap bugs can transmit data for hours upon hours.&nbsp;&nbsp;",
                "On eBay alone, you can see bugs that can be fitted into light switches, bulbs, smoke alarms, pens, clothing and so much more. These bugs aren&#8217;t of a professional level either and to the untrained eye, they are very difficult to spot.",
                "Professional bugs are so much smaller, have larger transmitting ranges and to the untrained, you would never know they were there."
            ]
        },
    ]
}

export default pageDetails