import React from 'react'
import ImgLink from '../Common/ImgLink'

const HeaderLg = ({children}) => {
  return (
    <header id="masthead" className="site-header">

		<div className="container">
		
		<div className="site-branding">
            <ImgLink route="/" title="Title" src="./images/logo.png" alt="Logo" className="siteLogo"/>
		</div>

		{children}

		</div>

		<div className="clear"></div>

	</header>
  )
}

export default HeaderLg