import React, {useCallback} from 'react'
import { Link } from 'react-router-dom';

const ScrollLink = ({to, children, ...props}) => {

    const scrollToTop = useCallback(
        () => {
            window.scrollTo({
                top: 0
            });
        }, []
    )
  return (
    <Link to={to} {...props} onClick={scrollToTop}>
            {children}
    </Link>
  )
}

export default ScrollLink