import React from 'react'
import PageHero from '../components/PageHero'

const Gallery = () => {
  return (
    <>
      <PageHero title="Gallery" className="pageHero--gallery" />
    </>
  )
}

export default Gallery