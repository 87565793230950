import React from 'react'

const Detail = ({title, src, alt, desc}) => {
  return (
    <React.Fragment>
        {title && <h2>{title}</h2>}
        {src && <figure className="wp-block-image size-large"><img decoding="async" width="840" height="360" src={`${src}.jpg`} alt={alt} className="wp-image-50" srcSet={`${src}.jpg 840w, ${src}-300x129.jpg 300w, ${src}-768x329.jpg 768w"`} sizes="(max-width: 840px) 100vw, 840px" /></figure>}
        {desc.map((para, index) => {
            return <p key={index}>{para}</p>
        })}
    </React.Fragment>
  )
}

export default Detail