import React from 'react'
import ImgLink from '../Common/ImgLink'
import ScrollLink from '../Common/ScrollLink'

const Service = ({title, src, route, desc}) => {
  return (
    <div className="serviceBox item" data-mh="serviceHeight">
      <ImgLink route={route} src={src} alt={title} className="maxWidth"/>
      
      <ScrollLink to={route} className="serviceTitle">{title}</ScrollLink>
      <p>{desc}</p>

    </div>

  )
}

export default Service