import React from 'react'
import PageHero from '../components/PageHero'
import DetailLayout from '../layout/DetailLayout'
import pageDetails from '../constants/pageDetails'

const About = () => {
  return (
    <>
	    <PageHero title="About" className="pageHero--about" />
      <DetailLayout data={pageDetails["bug-sweeps"]} />
    </>
  )
}

export default About