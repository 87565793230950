export const services = [
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    },
    {
        title: "GPS Tracking", 
        src: "./images/bug-sweeps/bug-sweeps-home.jpg",
        route: "/services/bug-sweeps",
        desc: "Protect your home and business with our bug sweeping service."
    }
]