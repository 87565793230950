import React from 'react';

const MainBlock = ({items}) => {
  return (
    <>
        <img src={items.logoSrc} alt={items.logoAlt} className="footerLogo" />
        <p className="smallText">{items.intro}</p>
    </>
  )
}

export default MainBlock