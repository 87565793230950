import React from 'react';
import { Link } from 'react-router-dom';

const Cta = ({ctaClass, number}) => {
  return (
    <div className={ctaClass}>
        <div className="container">
            <p>
                Call Us: 
                <Link to="tel:02920913294">
                    {number}
                </Link>
            </p>
        </div>
    </div>
)
}

export default Cta