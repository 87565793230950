import React from 'react';
import ImgLink from '../Common/ImgLink';

const NavHeader = ({responsiveCta, toggleNavbar}) => {
    return (
    
            <div className="whiteHead">
                <div className="container">
                    <div className="responsiveLogo">
                        <ImgLink route="/" src="./images/logo.png" alt="Logo" className="mobileLogo"/>
                    </div>

                    {responsiveCta}

                    <div className="burgerMenu">
                        <img src="./svg/hamburger.svg" alt="Menu" onClick={toggleNavbar}/>
                    </div>
                    <div className="clear"></div>
                </div>

            </div>
    )
}

export default NavHeader