import React from 'react';
import ScrollLink from '../components/Common/ScrollLink';
import HomeIntro from '../components/HomeIntro';
import PageHero from '../components/PageHero';
import { Link } from 'react-router-dom';
import ScrollToView from '../components/Common/ScrollToView';
import Poster from '../components/Poster';
import ButtonLink from '../components/Common/ButtonLink';

const Home = () => {

  return (
    <>

	<PageHero title="Discreet Private Investigations and Surveillance Throughout The TamilNadu" className="pageHero--home">
		<p>Our range of services cover the entire TamilNadu with our Head Office being based in <ScrollLink to={{}}>Chennai</ScrollLink> we can serve your requirements wherever you are</p>
		<ScrollLink to="/about-us" className="btn btn--primary  btn--toAbout">Learn More</ScrollLink>
	</PageHero>
	<HomeIntro 
	title="Why Cerpsoft"
	src="./images/home/privateinvestigation.jpg"
	alt="Private Investigation">
		<p>Our operatives are retired police detectives whose expertise in <Link to="/" className="contentLink">private investigation</Link>, surveillance and covert operations evolved from nationally accredited police training. This expertise was extended to training members of the National Crime Squad and other law enforcement covert operatives.</p>
		<p>We specialise in surveillance and offer a professional service throughout <Link to="/" className="contentLink">Chennai</Link>. Vee Track, GPS Tracking Solution offers a service of the highest professional standards to all clients based upon the instructions it is given and the work will be carried out with the highest standards of confidentiality.</p>
		
		<p>You can see our <Link to="/" className="contentLink">private investigation fees</Link> here.</p>

		<ScrollToView 
		id="services"
		title="Our Services"
		className="btn btn--primary"
		/>
	</HomeIntro>
	<Poster bgClass="section--middleCTA">
		<h2>From 2003 we have been helping various clients in Surveillance and Investigation work</h2>
		<p>Discrete services that deliver results throughout the Chennai.</p>
		
		<ul class="middleCTA">
			<li class="phone">1234567890</li>
			<li><ButtonLink route="/contact-us">Contact Us</ButtonLink></li>
		</ul>
	</Poster>
</>
  )
}

export default Home