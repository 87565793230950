import { useMemo } from 'react';
import {useLocation, Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import CopyRight from '../components/Footer/CopyRight';
import {MobileNav} from '../components/Navbar';
import Accreds from '../components/Accreds';
import HeaderLg from '../components/HeaderLg';
import { NavbarLg } from '../components/Navbar';
import TalkToUs from '../components/TalkToUs.jsx';
import Services from '../components/Services';

const Layout = () => {
  const {pathname} = useLocation();

  const classes = useMemo(() => {
      return pathname === "/" ?
      "" :
      "page-template-template-inner"
  }, [pathname])

  return (
    <main className={classes}>
      <div id="page" className="site">
        <HeaderLg>
          <NavbarLg />
        </HeaderLg>
        <Outlet />
        {
          pathname !== "/contact-us" &&
          <><Services title={pathname === "/" ? "Our Services" : "Other Services"}/>
          <TalkToUs /></>
        }
        <Accreds />
        <Footer />
        <CopyRight />
      </div>
      <MobileNav />
    </main>
  )
}

export default Layout