import React from 'react'
import ContactDetails from '../components/ContactDetails'
import PageHero from '../components/PageHero'

const Contact = () => {
  return (
    <>
      <PageHero title="Contact Us" className="pageHero--contact" />
      <div className="section section--white section--content">
        <div className="container">
          
          <div className="introText">
            <h2>Get In Touch With Us</h2>
            <p>Please feel free to contact us regarding any of our services. Our friendly team are here to offer advice and support no matter what your legal query.</p>
          </div>

          <div className="block40 pdLeft5">
            <ContactDetails styleClass="contactPageDetails"/>
          </div>

          <div className="clear"></div>

        </div>
      </div>
    </>
  )
}

export default Contact