import React,{useMemo} from 'react'
import { footerItems } from '../../constants/footerItems'

const ContactDetails = ({styleClass}) => {
    const offices = useMemo(() => footerItems['offices'], [])

  return (
    <div className={styleClass}>
        <h2>Get In Touch</h2>
        <ul className="services services--office">
        {offices.map((place, index) => (
            <React.Fragment key={index}>
                <li className={`${index ? 'office' : ''} officeName`}>{place.name}</li>
                <li className="tel">{place.tel}</li>
                <li>{place.address}</li>
            </React.Fragment>
        ))}
        </ul>			
    </div>
  )
}

export default ContactDetails