import React from 'react';
import { footerItems } from '../../constants/footerItems';
import AddressBlock from './AddressBlock';
import BlockSections from './BlockSections';
import FooterBlock from './FooterBlock';
import MainBlock from './MainBlock';

const Footer = () => {
  return (
    <footer id="colophon" className="site-footer">
		<div className="container">
			<div className="footerWrap">
                <FooterBlock>
                    <MainBlock items={footerItems["introduction"]}/>
                </FooterBlock>
                <FooterBlock>
                    <BlockSections title="Discreet Services" links={footerItems["Discreet Services"]} />
                </FooterBlock>
                <FooterBlock>
                    <BlockSections title="Discreet Services" links={footerItems["Discreet Services"]} />
                    <BlockSections title="Discreet Services" links={footerItems["Discreet Services"]} className="second" />
                </FooterBlock>
                <FooterBlock>
                    <AddressBlock />
                </FooterBlock>
			</div>
			<div className="clear"></div>
		</div>
	</footer>
  )
}

export default Footer