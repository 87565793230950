import React from 'react'
import { useParams } from 'react-router-dom'
import DetailLayout from '../../layout/DetailLayout';
import PageHero from '../../components/PageHero';
import pageDetails from '../../constants/pageDetails';

const Service = () => {
	const { serviceName } = useParams();
	return (
    <>

	<PageHero title={serviceName.split("-").join(" ")} className={`pageHero--${serviceName}`} />	
	<DetailLayout data={pageDetails[serviceName]} />
</>
  )
}

export default Service