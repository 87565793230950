import React from 'react'
import ScrollLink from '../Common/ScrollLink'


const MenuItem = ({route, label, menuType, children, ...props}) => {

  return (
    <li {...props}>
        <ScrollLink to={route}>
            {label}
        </ScrollLink>
        {children}
    </li>
  )
}

export default MenuItem