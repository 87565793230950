import React from 'react';
import { accreds } from '../../constants/accreds';
import Accred from './Accred';

const Accreds = () => {
    return (
        <div className="accreds">
            <div className="container">
                <ul className="accredsList">
                    {accreds.map(({src, alt}, index) => {
                        return <Accred key={index} src={src} alt={alt}/>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Accreds