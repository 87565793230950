import React from 'react';
import ScrollLink from '../Common/ScrollLink';
const TalkToUs = () => {
  return (
    <div className="section section--mainCTA">
        <div className="container">
            
            <div className="block70">
                <h2>Discreet Investigation services that get results.</h2>
            </div>
            
            <div className="block30">
                <ScrollLink to="contact-us" className="btn btn--primary">Talk To Us Now</ScrollLink>
            </div>

            <div className="clear"></div>

        </div>
    </div>
  )
}

export default TalkToUs