const menuItems = [
    {
        route: "/",
        label: "home",
        
    },
    {
        route: "/about-us",
        label: "About",
        
    },
    {
        route: {},
        label: "Services",
    
        sub: [{
            route: "/services/bug-sweeps",
            label: "GPS Tracking",
            
        }]
    },
    {
        route: "/gallery",
        label: "Gallery",
        
    },
    {
        route: "/contact-us",
        label: "Contact Us",
        
    },
]

export default menuItems