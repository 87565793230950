export const accreds = [
    {
        src: "./images/acred1.png",
        alt: "Something"
    },
    {
        src: "./images/acred1.png",
        alt: "Something"
    },
    {
        src: "./images/acred1.png",
        alt: "Something"
    },
    {
        src: "./images/acred1.png",
        alt: "Something"
    },
    {
        src: "./images/acred1.png",
        alt: "Something"
    },
    {
        src: "./images/acred1.png",
        alt: "Something"
    },
    {
        src: "./images/acred1.png",
        alt: "Something"
    }
]