import React from 'react';

const BlockSections = ({title, links, ...props}) => {
  return (
    <>
        <h3 {...props}>{title}</h3>

        <ul className="services">
            {links.map(({route, label}, index) => (
                <li key={index}><a href={route}>{label}</a></li>
            ))}
        </ul>
    </>
  )
}

export default BlockSections