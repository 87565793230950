import React from 'react'

const NavContents = ({navClasses, children}) => {
  return (
    <div className={navClasses}>
        <div className="container">
            <div className="menu-main-menu-container">
                <ul id="primary-menu" className="menu">
                    {children}
                </ul>
            </div>			
        </div>
    </div>
  )
}

export default NavContents