import {
createHashRouter,
RouterProvider,
} from "react-router-dom";
import { 
About, 
Contact,
Gallery,
Home,
Service
} from './pages';

import Layout from './layout/Layout';

const router = createHashRouter([
{
    path: "/",
    element: <Layout />,
    children: [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "about-us",
        element: <About />
    },
    {
        path: "contact-us",
        element: <Contact />
    },
    {
        path: "gallery",
        element: <Gallery />
    },
    {
        path: "services/:serviceName",
        element: <Service />
    }
    ]
    }
]);

const Router = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default Router