import React from 'react'
import ScrollLink from './ScrollLink'

const ImgLink = ({route, ...props}) => {
  return (
    <ScrollLink to={route}>
        <img {...props} />
    </ScrollLink>
  )
}

export default ImgLink