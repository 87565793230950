import React, { useState } from 'react'

const CopyRight = () => {
    const [year] = useState(new Date().getFullYear());

    return (
        <div className="socket">
            <div className="container">
                
                    <p>Copyright {year} by <a href="https://www.cerpsoft.in/" target="_blank" rel="noreferrer">Cerpsoft</a></p>
                
            </div>
        </div>
    )
}

export default CopyRight