import React from 'react'

const Poster = ({bgClass, children}) => {
  return (
    <div class={`section ${bgClass}`}>
		<div class="container">
		    <div class="centerText">
                {children}
			</div>
		</div>
	</div>
  )
}

export default Poster