import React from 'react'
import Detail from '../components/Common/Detail'
import ContactDetails from '../components/ContactDetails'

const DetailLayout = ({data}) => {
  return (
    <div className="section section--white section--content">
        <div className="container">
          
          <div className="block60 contentLeft">
            {data.map((data, index) => {
              return <Detail key={index} {...data}/>
            })}
          </div>

          <div className="block40 pdLeft5">
            <ContactDetails styleClass="contactDetails"/>
          </div>

          <div className="clear"></div>

        </div>
      </div>
  )
}

export default DetailLayout