import React from 'react'
import Service from './Service'
import { services } from '../../constants/services'

const Services = ({title}) => {
  return (
    
<div id="services" className="section section--grey">
	<div className="container">
		<div className="centerText">
			<h2>{title}</h2>
			<p className="subtitle">Discreet and professional investigation services that get results.</p>
		</div>

			<div className="serviceWrap">
        {services.map((service, index) => (
          <Service key={index} {...service}/>
        ))}

		<div className="clear"></div>


			</div>
	</div>
</div>
  )
}

export default Services