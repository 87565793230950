import React from 'react'

const PageHero = ({ title, children, ...rest }) => {
    return (
        <div className="pageHero" {...rest}>
            <div className="container">
                
                <div className="heroInner">
                    <h1>{title}</h1>
                    {children}
                </div>

            </div>
        </div>
    )
}

export default PageHero