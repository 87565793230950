import React, { useState, useCallback, useMemo } from 'react';
import Cta from './Cta';
import NavContents from './NavContents';
import NavHeader from './NavHeader';
import MenuItem from './MenuItem'
import menuItems from '../../constants/menuItems';

export const MobileNav = () => {
    const [isNavClose, setIsNavClose] = useState(true);
    const navClasses = useMemo(() => {
        return isNavClose ? 
        'responsiveList hide' : 
        'responsiveList'
    }, [isNavClose]);

    const toggleNavbar = useCallback(
        () => {
            setIsNavClose(isNavClose => !isNavClose);
        },
        []
    )

    const scrollToTop = useCallback(
        () => {
            window.scrollTo({
                top: 0
            });
        }, []
    )

    return (
        <div className="responsiveNav">
            <Cta number='1234567890' ctaClass='mobileCta' />
            <div className="responsiveHeader">
                <NavHeader 
                responsiveCta={<Cta number='1234567890' ctaClass='responsiveCta' />}
                toggleNavbar={toggleNavbar}
                />
                <NavContents navClasses={navClasses} toggleNavbar={toggleNavbar}>
                    {menuItems.map(props => {
                        return (
                            <React.Fragment key={props.label}>
                            <MenuItem {...props} onClick={() => (toggleNavbar(), scrollToTop())}/>
                            {props.sub && props.sub.map(item => {
                                return <MenuItem key={item.label} {...item} className='sub-menu' 
                                onClick={() => (toggleNavbar(), scrollToTop())}/>
                            })}
                            </React.Fragment>
                        )
                    })}
                </NavContents>
            </div>
        </div>
)
}

export const NavbarLg = () => {
    return (
        <nav id="site-navigation" className="main-navigation">
			
			<div className="menu-main-menu-container">
                <ul id="primary-menu" className="menu">
                    {menuItems.map(({sub, ...props}) => {
                    return (
                        <React.Fragment key={props.label}>
                        <MenuItem  {...props} className={sub && "menu-item-has-children"}>
                        {sub && sub.map((item) => [
                            <ul key={item.label} className="sub-menu">
                                <MenuItem  {...item} />
                            </ul>
                        ])}
                        </MenuItem>
                        </React.Fragment>
                    )
                    })}
                </ul>
            </div>		
        </nav>
    )
}
