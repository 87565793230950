import React, {useCallback} from 'react'

const ScrollToView = ({id, title, ...props}) => {

    const scrollToView = useCallback((id) => {

		const element = document.getElementById(id)
	
        element.scrollIntoView({
        behavior: 'smooth'
        })
    }, [])

    return (
        <div onClick={() => {
            scrollToView(id)
        }} {...props}>{title}</div>
    )
}

export default ScrollToView